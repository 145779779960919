// eslint-disable react-hooks/exhaustive-deps

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router, navigate } from '@reach/router';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { buildAPIURL } from '@beelineloans/cx-library/src/utils/helpers';
import { get } from '@beelineloans/cx-library/src/utils/fetch';

const CaptureCustomerCode = ({ location }) => {
  const path = location.pathname.slice(-1) === '/' ? location.pathname.slice(0, -1) : location.pathname;
  const parts = path.split('/');
  const code = parts.length > 0 ? parts[parts.length - 1] : null;

  useEffect(() => {
    if (!code) {
      console.error(`Could not find customer code in path: ${location.pathname}`);
      navigate(CONST.LINKS.MAIN.CALCULATORS.DEFAULT);
    } else {
      // lookup customer search details by code
      const apiKey = process.env.GATSBY_PROD === 'true' ? process.env.GATSBY_API_KEY : process.env.GATSBY_API_KEY_STAGING;
      get(buildAPIURL(`${CONST.LINKS.EXTERNAL.API.CODE}/${code}`), { 'x-api-key': apiKey })
        .then(response => {
          if (response.data.status === 'REDIRECT') {
            let url = '';
            if (response.data.url.indexOf('?') > -1) {
              url = `${response.data.url}&${(location.search || '').replace('?', '')}`;
            } else {
              url = `${response.data.url}&${location.search || ''}`;
            }

            navigate(url);
          } else {
            console.error(`Could not find customer code ${code}. Path: ${location.pathname}. Message: ${response.message}`);
            navigate(CONST.LINKS.MAIN.CALCULATORS.DEFAULT);
          }
        })
        .catch(() => {
          console.error(`Could not find customer code ${code}. Path: ${location.pathname}`);
          navigate(CONST.LINKS.MAIN.CALCULATORS.DEFAULT);
        });
    }
  }, []);

  return null;
};

CaptureCustomerCode.propTypes = {
  location: PropTypes.object
};

const CustomerRates = () => (
  <Router>
    <CaptureCustomerCode default noThrow />
  </Router>
);

export default CustomerRates;
